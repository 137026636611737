import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'

interface CampaignsOneImageProps {
	blok: any
	getTextPositionClass: (position: string) => string
}

const CampaignsOneImage = ({
	blok,
	getTextPositionClass,
}: CampaignsOneImageProps) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative mx-auto flex w-full max-w-screen-lg flex-col items-center px-4 py-3"
		>
			{blok.image?.filename && (
				<Link to={blok.linkToPageCampaignOneImage || '/'}>
					<div className="relative w-full">
						<img
							src={blok.image.filename}
							alt={blok.image.alt || 'ads'}
							className="w-full rounded-[8px] object-cover"
						/>
						<BlackBackgroundColorForImages
							className={
								blok.textPlacement === 'underImage' ||
								blok.textPlacement === 'downLeftCorner'
									? 'rounded-[8px]'
									: ''
							}
						/>
						{blok.textPlacement !== 'underImage' && (
							<PlentyButtonsAndLinks
								as="nav-sm"
								className={getTextPositionClass(blok.textPlacement)}
							>
								{blok.body}
							</PlentyButtonsAndLinks>
						)}
					</div>
				</Link>
			)}
			{blok.textPlacement === 'underImage' && (
				<PlentyButtonsAndLinks as="nav-sm" className="mt-2 text-center">
					{blok.body}
				</PlentyButtonsAndLinks>
			)}
		</div>
	)
}

export default CampaignsOneImage
