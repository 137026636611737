import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type XlSpacerStoryblok } from '#types/component-types-sb'

const xlSpacer = ({ blok }: XlSpacerStoryblok) => {
	const isOn = blok.spacingSm !== undefined ? blok.spacingSm : true

	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="xl"
			isOn={isOn}
		/>
	)
}

export default xlSpacer
