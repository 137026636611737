import { useLoaderData } from '@remix-run/react'
import { type loader } from '#app/routes/_public+/_layout'
import {
	type CampaignStoryblok,
	type CampaignCallerStoryblok,
} from '#types/component-types-sb'
import Campaign from './campaign'

interface CampaignCallerProps {
	blok: CampaignCallerStoryblok
}

const CampaignCaller = ({ blok }: CampaignCallerProps) => {
	// Retrieve data from the loader
	const data = useLoaderData<typeof loader>()
	const campaigns = data.mobileNavCampaigns || [] // Get campaigns from loader data



	// Extract the campaign UUID from blok
	const selectedCampaignUUID =
		blok.campaignReference && blok.campaignReference.length > 0
			? blok.campaignReference[0]
			: null


	// Find the campaign by UUID
	const selectedCampaign =
		campaigns.length > 0 && selectedCampaignUUID
			? campaigns.find(campaign => campaign.uuid === selectedCampaignUUID)
			: null

	// Assert the type of the campaign content to match CampaignStoryblok
	const campaignContent = selectedCampaign?.content as
		| CampaignStoryblok
		| undefined

	// Render the campaign if it's found, otherwise show an error or fallback
	return (
		<div>
			{campaignContent ? (
				<Campaign blok={campaignContent} />
			) : (
				<p>No campaign found or data missing</p>
			)}
		</div>
	)
}

export default CampaignCaller
