import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type XsSpacerStoryblok } from '#types/component-types-sb'

const xsSpacer = ({ blok }: XsSpacerStoryblok) => {
	const isOn = blok.spacingXs !== undefined ? blok.spacingXs : true

	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="xs"
			isOn={isOn}
		/>
	)
}

export default xsSpacer
