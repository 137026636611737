import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type MdSpacerStoryblok } from '#types/component-types-sb'

const mdSpacer = ({ blok }: MdSpacerStoryblok) => {
	const isOn = blok.spacingSm !== undefined ? blok.spacingSm : true

	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="md"
			isOn={isOn}
		/>
	)
}

export default mdSpacer
