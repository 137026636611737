import { type CampaignStoryblok } from '#types/component-types-sb'
import CampaignFourImages from '../navigation/mobile-navbar/campaigns/campaign-four-images'
import CampaignsOneImage from '../navigation/mobile-navbar/campaigns/campaign-one-image'
import CampaignTextOnly from '../navigation/mobile-navbar/campaigns/campaign-text-only'
import CampaignsTwoImages from '../navigation/mobile-navbar/campaigns/campaign-two-images'

interface CampaignProps {
	blok: CampaignStoryblok
}

const Campaign = ({ blok }: CampaignProps) => {
	const getTextPositionClass = (position: string) => {
		switch (position) {
			case 'underImage':
				return `mt-2 text-center text-${blok.textColor}`
			case 'centerImage':
				return `absolute inset-0 flex items-center justify-center text-center text-${blok.textColor}`
			case 'downLeftCorner':
				return `absolute bottom-0 left-0 p-2 text-${blok.textColor}`
			default:
				return ''
		}
	}

	const campaignStart = new Date(blok.campaignStart)
	const campaignEnd = new Date(blok.campaignEnd)
	const currentDate = new Date()
	if (currentDate < campaignStart || currentDate > campaignEnd) {
		return null
	}

	switch (blok.whatCampaignYouWannaUse) {
		case 'OneImageCampaign':
			return (
				<CampaignsOneImage
					blok={blok}
					getTextPositionClass={getTextPositionClass}
				/>
			)

		case 'TwoImageCampaign':
			return (
				<CampaignsTwoImages
					blok={blok}
					getTextPositionClass={getTextPositionClass}
				/>
			)

		case 'FourImageCampaign':
			return (
				<CampaignFourImages
					blok={blok}
					getTextPositionClass={getTextPositionClass}
				/>
			)
			
		case 'CampaignTextOnly':
			return (
				<CampaignTextOnly
				blok={blok}
				
				/>
			)

		default:
			return <p>No campaign type selected</p>
	}
}

export default Campaign
