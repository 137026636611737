import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type H3SpacerStoryblok } from '#types/component-types-sb'

const H3Spacer = ({ blok }: H3SpacerStoryblok) => {
	const isOn = blok.spacingSm !== undefined ? blok.spacingSm : true

	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="h3"
			isOn={isOn}
		/>
	)
}

export default H3Spacer
